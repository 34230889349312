module services {
    export module integration {
        export class interfaceClientService implements interfaces.integration.IInterfaceClientService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getInterfaceClientSummary(numberofDays: number): ng.resource.IResourceClass<interfaces.integration.IInterfaceClientSummary> {
                return this.$resource<interfaces.integration.IInterfaceClientSummary>(this.ENV.DSP_URL + "InterfaceClient/GetIntegrationClientSummary", {
                    numberofDays: numberofDays 
                });
            }

            getForDropdown(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "InterfaceClient/GetForDropdown", {
                });
            }   


        }
    }
    angular.module("app").service("interfaceClientService", services.integration.interfaceClientService);
}